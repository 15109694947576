<template>
    <v-content>
        <!--<v-breadcrumbs :items="items" large></v-breadcrumbs>-->
        <v-container fluid>
            <!-- <v-row no-gutters>
                <v-col cols="10"></v-col>
                <v-col cols="2">
                    <v-btn cols="2" class="success" @click.stop="onDownload">
                        <v-icon>get_app</v-icon>
                        Export
                    </v-btn>
                </v-col>
            </v-row>-->

            <v-expansion-panels multiple hover>
                <v-expansion-panel>
                    <v-expansion-panel-header>{{$t('filter')}}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container fluid>
                            <v-row col="12">
                                <v-row align="center" justify="start" class="mt-4">

                                    <v-col class="d-flex" cols="12" sm="4">
                                        <v-autocomplete v-model="serverParams.HouseID"
                                                        :items="HouseList"
                                                        :label="$t('select_house')"
                                                        clearable
                                                        dense
                                                        outlined
                                                        item-text="value"
                                                        item-value="id"></v-autocomplete>
                                    </v-col>



                                    <v-col class="d-flex" cols="12" sm="4">
                                        <v-menu v-model="snackbar.menu1"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field v-model="serverParams.FromDate"
                                                              :label="$t('from_date')"
                                                              clearable
                                                              dense
                                                        outlined
                                                              prepend-inner-icon="event"
                                                              readonly
                                                              v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="serverParams.FromDate">

                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>

                                    <v-col class="d-flex" cols="12" sm="4">
                                        <v-menu v-model="snackbar.menu2"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field v-model="serverParams.ToDate"
                                                              :label="$t('to_date')"
                                                              clearable
                                                              dense
                                                        outlined
                                                              prepend-inner-icon="event"
                                                              readonly
                                                              v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="serverParams.ToDate">

                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>

                                    <v-col class="d-flex" cols="12" sm="12">
                                        <v-spacer></v-spacer>
                                        <v-btn class="mx-2" dark small color="primary" @click="loadItems">
                                            <v-icon dark> search</v-icon>
                                            {{$t('search')}}
                                        </v-btn>
                                        <v-btn color="rgb(157, 25, 25)" small dark @click="clearInput">
                                            <i class="material-icons">clear</i>{{$t('clear')}}
                                        </v-btn>
                                        <v-btn @click.stop="onDownload" small class="success float-right mx-2">
                                            <v-icon>get_app</v-icon>
                                            {{$t('download_to_excel')}}
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                    </v-col>
                                </v-row>
                            </v-row>
                        </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <vue-good-table mode="remote"
                            @on-page-change="onPageChange"
                            @on-sort-change="onSortChange"
                            @on-column-filter="onColumnFilter"
                            @on-per-page-change="onPerPageChange"
                            :isLoading.sync="isLoading"
                            :pagination-options="{ enabled: true,mode: 'records' }"
                            :rows="rows"
                            :columns="columns"
                            styleClass="vgt-table condensed">
                <template slot="table-column" slot-scope="props">
                    <span>
                        {{ $t(props.column.label) }}
                    </span>
                </template>
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'datedOn'">
                        <span>{{props.row.datedOn | moment("DD-MMM-YYYY")}}</span>
                    </span>
                    <!--<span v-else-if="props.column.field == 'actions'">
            <v-icon small class="mr-2" @click.stop="editItem(props.row, 'HistoryForm')">edit</v-icon>
            <v-icon small @click.stop="deleteItem(props.row)">delete</v-icon>
        </span>-->
                </template>
            </vue-good-table>
            <v-snackbar v-model="snackbar.snackbar"
                        color="success"
                        :timeout="snackbar.timeout"
                        top>
                {{ callbackResponse.message }}
                <v-btn dark
                       text
                       @click="snackbar.snackbar = false">
                    Close
                </v-btn>
            </v-snackbar>
        </v-container>
        <v-btn dark color="rgb(157, 25, 25)" @click="dialogueClose" to="/IndividualHomestayReport">
            {{$t("back")}}
        </v-btn>
    </v-content>
</template>

<script>
    import axios from "axios";

    export default {
        name: "MountaineeringHistoryList",
        props: [
            'moduleName'
        ],
        components: {},
        computed: {
            reportIsMonthWise() {
                return this.serverParams.ReportType === "monthwise";
            }
        },
        methods: {

            formResponse(data) {
                console.log({ data })

            },

            async onDownload() {
                let param = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    HomestayHouseID: this.serverParams.HouseID != null && this.serverParams.HouseID != undefined ? parseInt(this.serverParams.HouseID) : 0,
                    DivisionID: this.serverParams.DivisionID,
                    BranchID: this.serverParams.BranchID,
                    FromDate: this.serverParams.FromDate,
                    ToDate: this.serverParams.ToDate,
                };

                await axios({
                    url: 'Hotel/DownloadHomestayOccupiedPAXReportAsync',
                    method: 'POST',
                    responseType: 'blob',
                    data: param
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'HomestayOccupiedPAXReportAsync.xlsx'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                });
            },

            dialogueClose() {
                this.historyData.editItemIndex = null
                this.snackbar.formModal = false
                this.snackbar.createFormComponent = false
                this.snackbar.editFormComponent = false
            },

            async loadItems() {
                this.loading = true;

                let param = {

                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    HomestayHouseID: this.serverParams.HouseID != null && this.serverParams.HouseID != undefined ? parseInt(this.serverParams.HouseID) : 0,
                    DivisionID: this.serverParams.DivisionID,
                    FromDate: this.serverParams.FromDate,
                    ToDate: this.serverParams.ToDate,
                    BranchID: this.serverParams.BranchID
                };
                axios.post("Hotel/GetHomestayOccupiedPAXReportAsync", param).then(response => {
                    this.loading = false;
                    this.rows = response.data.data;
                    this.totalRecords = response.data.totalCount;
                }).catch(err => {
                    console.log(err)
                });
                console.log("load", this.apiData);
            },
            clearInput() {
                this.serverParams.HouseID = null
                this.serverParams.DivisionID = null
                this.serverParams.BranchID = null
                this.serverParams.FromDate = null
                this.serverParams.ToDate = null
                this.loadItems();

            },

            async getHouseList() {
                const HouseList = await axios.get('Hotel/DDLHouseList')
                this.HouseList = HouseList.data
                console.log('House list: ', this.HouseList)
            },

            async getBranchList(id) {
                const BranchList = await axios.get('Branch/DDLBranchListForHomeStayByHouseID?HouseID=' + id)
                this.BranchList = BranchList.data
            },


            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({ page: params.currentPage });
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({ perPage: params.currentPerPage });
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },

            //addModal() {
            //    this.snackbar.formModal = true
            //    this.snackbar.createFormComponent = true
            //},

            //editItem(item) {
            //    this.snackbar.formModal = true
            //    this.snackbar.editFormComponent = true
            //    this.historyData.editItemIndex = item.personInformationID
            //},

            //deleteItem(props) {
            //    this.dialogDelete = true;
            //    this.deleteFormData.url = "Mountain/DeleteHistoryInformationByIDAsync/" + props.personInformationID;
            //},
        },
        mounted() {

            this.getHouseList();
            this.loadItems();


        },
        data() {
            return {
                tab: 'tab-monthlyReport',
                callbackResponse: {
                    timeout: 6000
                },
                items: [
                    {
                        text: "Dashboard",
                        exact: true,
                        to: '/HomeStayDashboard'
                    },
                    {
                        text: "Monthly-Yearly Report",
                        disabled: true,
                        exact: true
                    }],
                columns: [
                    
                    {
                        label: 'house_name',
                        field: 'houseName'
                    },
                    {
                        label: 'house_no',
                        field: 'houseNumber'
                    },
                    {
                        label: 'occupied_pax',
                        field: 'occupiedPax'
                    },
                    


                ],
                rows: [],
                ReportTypes: [
                    {
                        id: "yearwise", value: "yearwise"
                    },
                    {
                        id: "monthwise", value: "monthwise"
                    }
                ],
                dialogDelete: false,
                deleteFormData: [],
                formdata: [],
                HouseList: [],
                BranchList: [],

                historyData: {
                    totalRows: 0,
                    editItemIndex: null
                },
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "HouseName",
                            type: "asc"
                        }
                    ],
                    page: 1,
                    perPage: 10,
                    ReportType: ""
                },

                paginationOptions: {
                    enabled: true,
                    mode: 'records',
                    perPageDropdown: [20, 50, 100, 200],
                    dropdownAllowAll: false,
                    nextLabel: 'next',
                    prevLabel: 'prev',
                    rowsPerPageLabel: 'Rows per page',
                    ofLabel: 'of',
                    pageLabel: 'page', // for 'pages' mode
                    allLabel: 'All',
                },
                snackbar: {
                    color: '',
                    snackbar: false,
                    text: '',
                    timeout: 6000,
                    isLoading: false,
                    formModal: false,
                    createFormComponent: false,
                    editFormComponent: false,
                    menu1: false,
                    menu2: false,

                    dynamicComponent: {
                        current: 'HistoryForm'
                    }
                }
            };
        },

    }
</script>

<style scoped>
</style>